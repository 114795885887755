import React from "react"
import LayoutComponent from "../../components/Layout.js"
import RoentgenCT from "../../components/RoentgenCT.js"
import Knap from "../../components/Knap.js"

const RoentgenPage = () => (
    <LayoutComponent>
        <h1>Röntgen och CT</h1>
        <p>I samarbete med våra leverantörer kan vi leverera nästen allt inom röntgen- och CT tillbehör. Vi kan bland annat hjälpa till med röntgenskydd och blyskydd i form av röntgenförkläder, blyförkläder, blyskjöldar, blytäckning, gonadskydd eller röntgenglasögon, samt att leverera röntgentillbehör till positionering, kvalitetskontrol (QA), dosimetre, dosimetri, scanning, scanningsutrustning, bilddiagnostik, kalibrering, markeringar och mammografi.</p>
        <p>Där utöver är vi leveringsduktiga inom fantomer, träningsfantomer, vävssimulation och patientsimulatorer.</p>
        <h1>Våra leverantörer</h1>
        <RoentgenCT />    
        <Knap />
    </LayoutComponent>
)
export default RoentgenPage